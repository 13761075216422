var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobile)?_c('aside',{staticClass:"flex h-full",class:{
      'mobile-position': _vm.isMobile,
    }},[_c('div',{staticClass:"flex flex-1",class:{
        'absolute-sidebar': _vm.isMobile,
      }},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showSecondarySidebar)?_c('primary-sidebar',{staticClass:"z-20",attrs:{"logo-source":_vm.globalConfig.logoThumbnail,"installation-name":_vm.globalConfig.installationName,"is-a-custom-branded-instance":_vm.isACustomBrandedInstance,"account-id":_vm.accountId,"menu-items":_vm.primaryMenuItems,"chat-menu-item":_vm.chatMenuItem,"active-menu-item":_vm.activePrimaryMenu.key},on:{"toggle-accounts":_vm.toggleAccountModal,"key-shortcut-modal":_vm.toggleKeyShortcutModal,"open-notification-panel":_vm.openNotificationPanel}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showSecondarySidebar)?_c('secondary-sidebar',{class:['z-10', _vm.sidebarClassName],attrs:{"account-id":_vm.accountId,"inboxes":_vm.inboxes,"labels":_vm.labels,"teams":_vm.teams,"custom-views":_vm.customViews,"menu-config":_vm.activeSecondaryMenu,"current-role":_vm.currentRole,"is-on-chatwoot-cloud":_vm.isOnChatwootCloud},on:{"add-label":_vm.showAddLabelPopup,"toggle-accounts":_vm.toggleAccountModal}}):_vm._e()],1)],1)]):_c('aside',{staticClass:"flex h-full",class:{
      'mobile-position': _vm.isMobile,
    }},[_c('primary-sidebar',{attrs:{"logo-source":_vm.globalConfig.logoThumbnail,"installation-name":_vm.globalConfig.installationName,"is-a-custom-branded-instance":_vm.isACustomBrandedInstance,"account-id":_vm.accountId,"menu-items":_vm.primaryMenuItems,"chat-menu-item":_vm.chatMenuItem,"active-menu-item":_vm.activePrimaryMenu.key},on:{"toggle-accounts":_vm.toggleAccountModal,"key-shortcut-modal":_vm.toggleKeyShortcutModal,"open-notification-panel":_vm.openNotificationPanel}}),_vm._v(" "),(_vm.showSecondarySidebar)?_c('secondary-sidebar',{class:[_vm.computedAbsoluteSidebar, _vm.sidebarClassName],attrs:{"account-id":_vm.accountId,"inboxes":_vm.inboxes,"labels":_vm.labels,"teams":_vm.teams,"custom-views":_vm.customViews,"menu-config":_vm.activeSecondaryMenu,"current-role":_vm.currentRole,"is-on-chatwoot-cloud":_vm.isOnChatwootCloud},on:{"add-label":_vm.showAddLabelPopup,"toggle-accounts":_vm.toggleAccountModal}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }