import en from './locale/en';
import es from './locale/es';
import pt_BR from './locale/pt_BR';
// import survey i18n
import en_survey from '../../../../app/javascript/survey/i18n/locale/en.json';
import es_survey from '../../../../app/javascript/survey/i18n/locale/es.json';
import pt_BR_survey from '../../../../app/javascript/survey/i18n/locale/pt_BR.json';

// Merge survey translations into existing language objects
Object.assign(en, en_survey);
Object.assign(es, es_survey);
Object.assign(pt_BR, pt_BR_survey);

export default {
  en,
  es,
  pt_BR,
  // en_survey,
  // es_survey,
  // pt_BR_survey,
};
